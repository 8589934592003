<template>
  <div class="home">
<!--    <img alt="Vue logo" src="../assets/logo.png" />
    <HelloWorld msg="Welcome to Plotz" />-->
    <h1>Homepage</h1>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
//    HelloWorld
  }
};
</script>
